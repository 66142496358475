import axios from "axios";
export const globalMixins = {
    data() {
        return {
            // job_types : [
            //     {id : 1, type : '1S Base Model - 1S FR5 Model'},
            //     {id : 2, type : '2S Base Model - 2S FR5 Model'},
            //     {id : 3, type : '3S Base Model - 3S FR5 Model'},
            //     {id : 4, type : '1S NatHERS Base Model - 1S NatHERS Model'},
            //     {id : 5, type : '2S NatHERS Base Model - 2S NatHERS Model'},
            //     {id : 6, type : '3S NatHERS Base Model - 3S NatHERS Model'},
            //     {id : 7, type : '1S DB Base Model - 1S Design Builder Model'},
            //     {id : 8, type : '2S DB Base Model - 2S Design Builder Model'},
            //     {id : 9, type : '3S DB Base Model - 3S Design Builder Model'},
            //     {id : 10, type : '1S Loft Base Model - 1S Loft FR5 Model'},
            //     {id : 11, type : '1S DB + Base Model - 1S Design Builder + FR5 Model'},
            //     {id : 12, type : '2S DB + Base Model - 2S Design Builder + FR5 Model'},
            //     {id : 13, type : '3S DB + Base Model - 3S Design Builder + FR5 Model'},
            //     {id : 14, type : 'Class 2 Unit (Single)'},
            //     {id : 15, type : 'Class 2 Unit (Batch)'},
            //     {id : 16, type : 'Shading Diagram (3)'},
            //     {id : 17, type : 'Shading Diagram (6)'},
            //     {id : 18, type : 'Other'},
            // ],
            lbs_status : [
                {
                    value : 'allocated',
                    text : 'Allocated'
                },
                {
                    value : 'started',
                    text : 'Processing'
                },
                {
                    value : 'submitted for checking',
                    text : 'For Checking'
                },
                {
                    value : 'cancelled',
                    text : 'Cancelled'
                },
                {
                    value : 'completed',
                    text : 'Completed'
                }
            ],
            bph_status : [
                {
                    value : 'allocated',
                    text : 'Allocated'
                },
                {
                    value : 'accepted',
                    text : 'Accepted'
                },
                {
                    value : 'processing',
                    text : 'Processing'
                },
                {
                    value : 'submitted for checking',
                    text : 'For Checking'
                },
                {
                    value : 'rejected',
                    text : 'Rejected'
                },
                {
                    value : 'cancelled',
                    text : 'Cancelled'
                },
                {
                    value : 'awaiting further info',
                    text : 'Awaiting Further Info'
                },
                {
                    value : 'pending',
                    text : 'Pending'
                },
                {
                    value : 'for discussion',
                    text : 'For Discussion'
                },
                {
                    value : 'completed',
                    text : 'Completed'
                },
                {
                    value : 'revision request',
                    text : 'Revision Request'
                },
                {
                    value : 'revised',
                    text : 'Revised'
                },
                {
                    value : 'EA Certified HERS (certified and send)',
                    text : 'EA Certified HERS (certified and send)'
                },
                {
                    value : 'EA Certified PS',
                    text : 'EA Certified PS'
                },
                {
                    value : 'EA Certified HERS/PS',
                    text : 'EA Certified HERS/PS'
                },
                {
                    value : 'LOG',
                    text : 'LOG'
                }
            ],
            priorities : [
                'Top (COB)',
                'High (1 day)',
                'Standard (2 days)',
                'Standard (3 days)',
                'Standard (4 days)',
                'Low (5 days)',
                'Low (6 days)',
                'Low (7 days)',
            ],
            bph_priorities : [
                'Top (COB)',
                'Standard (1 day)',
                'Standard (2 days)',
            ],
            lbs_headers : [
                {
                    text: 'Date',
                    align: 'start',
                    sortable: true,
                    value: 'date_submitted',
                },
                {
                    text: 'Deadline',
                    align: 'start',
                    sortable: true,
                    value: 'deadline',
                },
                {
                    text: 'Time Remaining',
                    align: 'start',
                    sortable: true,
                    value: 'time_remaining',
                },
                {
                    text: 'Client',
                    align: 'start',
                    sortable: true,
                    value: 'client.company',
                },
                {
                    text: 'Job Type',
                    align: 'start',
                    sortable: true,
                    value: 'job_type',
                },
                {
                    text: 'State',
                    align: 'start',
                    sortable: true,
                    value: 'state',
                },
                {
                    text: 'Staff',
                    align: 'start',
                    sortable: true,
                    value: 'staff',
                },
                {
                    text: 'Priority',
                    align: 'start',
                    sortable: true,
                    value: 'priority',
                },
                {
                    text: 'LBS Ref #',
                    align: 'start',
                    sortable: true,
                    value: 'lbs_ref_no',
                },
                {
                    text: 'Plan Complexity',
                    align: 'start',
                    sortable: true,
                    value: 'plan_complexity',
                },
                {
                    text: 'Checker',
                    align: 'start',
                    sortable: false,
                },
                {
                    text: 'Job Status',
                    align: 'start',
                    sortable: false,
                },
                {
                    text: 'Notes',
                    align: 'start',
                    sortable: false,
                },
                {
                    text: 'Details',
                    align: 'start',
                }
            ],
            bph_headers : [
                {
                    text: 'Submission Date',
                    align: 'start',
                    sortable: true,
                    value: 'date_submitted',
                },
                {
                    text: 'Priority',
                    align: 'start',
                    sortable: true,
                    value: 'priority',
                },
                {
                    text: 'NCC',
                    align: 'start',
                    sortable: true,
                    value: 'version',
                },
                {
                    text: 'Job Request Type',
                    align: 'start',
                    sortable: true,
                    value: 'job_type',
                },
                {
                    text: 'Job Number',
                    align: 'start',
                    sortable: true,
                    value: 'job_number',
                },
                {
                    text: 'Client',
                    align: 'start',
                    sortable: true,
                    value: 'company_name',
                },  
                {
                    text: 'Star Rating',
                    align: 'start',
                    sortable: true,
                    value: 'star_rating',
                },
                {
                    text: 'Climate Zone',
                    align: 'start',
                    sortable: true,
                    value: 'climate_zone',
                },
                {
                    text: 'Job Address',
                    align: 'start',
                    sortable: true,
                    value: 'job_address',
                },
                {
                    text: 'Staff',
                    align: 'start',
                    align: 'staff',
                },
                {
                    text: 'Job Status',
                    align: 'start',
                    align: 'job_status',
                },
                {
                    text: 'Details',
                    align: 'start',
                    align: 'job_status',
                }
            ],
            regions : [
                {"id":0, "value":''},
                {"id":"1 - Darwin", "value":"1 - Darwin"},
                {"id":"2 - Port Hedland", "value":"2 - Port Hedland"}, 
                {"id":"3 - Longreach", "value":"3 - Longreach"},
                {"id":"4 - Carnarvon", "value":"4 - Carnarvon"},
                {"id":"5 - Townsville", "value":"5 - Townsville"},
                {"id":"6 - Alice Springs", "value":"6 - Alice Springs"},
                {"id":"7 - Rockhampton", "value":"7 - Rockhampton"},
                {"id":"8 - Moree", "value":"8 - Moree"},
                {"id":"9 - Amberly", "value":"9 - Amberly"},
                {"id":"10 - Brisbane", "value":"10 - Brisbane"},
                {"id":"11 - Coffs Harbour", "value":"11 - Coffs Harbour"},
                {"id":"12 - Geraldton", "value":"12 - Geraldton"},
                {"id":"13 - Perth", "value":"13 - Perth"},
                {"id":"14 - Armidale", "value":"14 - Armidale"},
                {"id":"15 - Williamtown", "value":"15 - Williamtown"},
                {"id":"16 - Adelaide", "value":"16 - Adelaide"},
                {"id":"17 - Sydney East", "value":"17 - Sydney East"},
                {"id":"18 - Nowra", "value":"18 - Nowra"},
                {"id":"19 - Charleville", "value":"19 - Charleville"},
                {"id":"20 - Wagga", "value":"20 - Wagga"},
                {"id":"21 - Melbourne", "value":"21 - Melbourne"},
                {"id":"22 - East Sale", "value":"22 - East Sale"},
                {"id":"23 - Launceston", "value":"23 - Launceston"},
                {"id":"24 - Canberra", "value":"24 - Canberra"},
                {"id":"25 - Cabramurra", "value":"25 - Cabramurra"},
                {"id":"26 - Hobart", "value":"26 - Hobart"},
                {"id":"27 - Mildura", "value":"27 - Mildura"},
                {"id":"28 - Richmond (NSW)", "value":"28 - Richmond (NSW)"},
                {"id":"29 - Weipa", "value":"29 - Weipa"},
                {"id":"30 - Wyndham", "value":"30 - Wyndham"},
                {"id":"31 - Willis Island", "value":"31 - Willis Island"},
                {"id":"32 - Cairns", "value":"32 - Cairns"},
                {"id":"33 - Broome", "value":"33 - Broome"},
                {"id":"34 - Learmouth", "value":"34 - Learmouth"},
                {"id":"35 - Mackay", "value":"35 - Mackay"},
                {"id":"36 - Gladstone", "value":"36 - Gladstone"},
                {"id":"37 - Halls Creek", "value":"37 - Halls Creek"},
                {"id":"38 - Tennant Creek", "value":"38 - Tennant Creek"},
                {"id":"39 - Mt Isa", "value":"39 - Mt Isa"},
                {"id":"40 - Newman", "value":"40 - Newman"},
                {"id":"41 - Giles", "value":"41 - Giles"},
                {"id":"42 - Meekatharra", "value":"42 - Meekatharra"},
                {"id":"44 - Kalgoorlie", "value":"44 - Kalgoorlie"},
                {"id":"45 - Woomera", "value":"45 - Woomera"},
                {"id":"46 - Cobar", "value":"46 - Cobar"},
                {"id":"47 - Bickley", "value":"47 - Bickley"},
                {"id":"48 - Dubbo", "value":"48 - Dubbo"},
                {"id":"49 - Katanning", "value":"49 - Katanning"},
                {"id":"50 - Oakey", "value":"50 - Oakey"},
                {"id":"51 - Forrest", "value":"51 - Forrest"},
                {"id":"52 - Swanbourne", "value":"52 - Swanbourne"},
                {"id":"53 - Ceduna", "value":"53 - Ceduna"},
                {"id":"54 - Mandurah", "value":"54 - Mandurah"},
                {"id":"55 - Esperance", "value":"55 - Esperance"},
                {"id":"56 - Mascot", "value":"56 - Mascot"},
                {"id":"57 - Manjimup", "value":"57 - Manjimup"},
                {"id":"58 - Albany", "value":"58 - Albany"},
                {"id":"59 - Mt Lofty", "value":"59 - Mt Lofty"},
                {"id":"60 - Tullamarine", "value":"60 - Tullamarine"},
                {"id":"61 - Mt Gambier", "value":"61 - Mt Gambier"},
                {"id":"62 - Moorabbin", "value":"62 - Moorabbin"},
                {"id":"63 - Warnambool", "value":"63 - Warnambool"},
                {"id":"64 - Cape Otway", "value":"64 - Cape Otway"},
                {"id":"65 - Orange", "value":"65 - Orange"},
                {"id":"66 - Ballarat", "value":"66 - Ballarat"},
                {"id":"67 - Low Head", "value":"67 - Low Head"},
                {"id":"68 - Launceston Air", "value":"68 - Launceston Air"},
                {"id":"69 - Thredbo", "value":"69 - Thredbo"},
            ],
            times_of_day : [
                {time : '00:00:00', text : '12:00 AM'},
                {time : '01:00:00', text : '01:00 AM'},
                {time : '02:00:00', text : '02:00 AM'},
                {time : '03:00:00', text : '03:00 AM'},
                {time : '04:00:00', text : '04:00 AM'},
                {time : '05:00:00', text : '05:00 AM'},
                {time : '06:00:00', text : '06:00 AM'},
                {time : '07:00:00', text : '07:00 AM'},
                {time : '08:00:00', text : '08:00 AM'},
                {time : '09:00:00', text : '09:00 AM'},
                {time : '10:00:00', text : '10:00 AM'},
                {time : '11:00:00', text : '11:00 AM'},
                {time : '12:00:00', text : '12:00 AM'},
                {time : '13:00:00', text : '01:00 PM'},
                {time : '14:00:00', text : '02:00 PM'},
                {time : '15:00:00', text : '03:00 PM'},
                {time : '16:00:00', text : '04:00 PM'},
                {time : '17:00:00', text : '05:00 PM'},
                {time : '18:00:00', text : '06:00 PM'},
                {time : '19:00:00', text : '07:00 PM'},
                {time : '20:00:00', text : '08:00 PM'},
                {time : '21:00:00', text : '09:00 PM'},
                {time : '22:00:00', text : '10:00 PM'},
                {time : '23:00:00', text : '11:00 PM'},
            ],
            access_menus : [
                {
                    icon : 'mdi-monitor-dashboard',
                    text : 'Dashboard',
                    link : 'Dashboard',
                    access : ['admin', 'staff', 'supervisor']
                },
                {
                    icon : 'mdi-table',
                    text : 'Timesheet',
                    link : 'TimeSheet',
                    access : ['admin']
                },
                {
                    icon : 'mdi-table',
                    text : 'Timesheet',
                    link : 'StaffTimeSheet',
                    access : ['staff', 'supervisor']
                },
                {
                    icon : 'mdi-clock',
                    text : 'Business Time Settings',
                    link : 'BusinessTime',
                    access : ['admin']
                },
                {
                    icon : 'mdi-calendar-remove',
                    text : 'Holidays',
                    link : 'Holidays',
                    access : ['admin']
                },
                {
                    icon : 'mdi-calendar-account',
                    text : 'Leaves',
                    link : 'Leaves',
                    access : ['staff', 'supervisor']
                },
                {
                    icon : 'mdi-calendar-account',
                    text : 'Leaves',
                    link : 'StaffLeaves',
                    access : ['admin']
                },
                {
                    icon : 'mdi-domain',
                    text : 'Companies',
                    link : 'Companies',
                    access : ['admin']
                },
                {
                    icon : 'mdi-account-tie',
                    text : 'LDS Users',
                    link : 'UserList',
                    access : ['admin']
                }
            ],
            bph_email_items : {
                sec1 : [
                    'The house will still rate at 6.3 stars with your proposed changes and as per previous recommendations to comply. No further changes to plans necessary.',
                    'The house rates at  stars with the following specifications:',
                    'The house rates at  stars.  No further changes to plans necessary.',
                    '*The house rates at  stars with the following specifications:'
                ],
                sec2 : [
                    'Slab on ground (floor coverings as per software default: Tiles to wet areas + Kitchen, Carpet with underlay to rest of house)',
                    'Slab on ground (floor coverings as per plans: Laminate to Entry + Fam/Meal/Kit + Bed Hall, Tiles to wet areas, Carpet with underlay to rest of house)',
                    'Slab on ground (floor coverings as per plans: Vinyl to Entry + Fam/Meal/Kit + Bed Hall, Tiles to wet areas, Carpet with underlay to rest of house)',
                    'Slab on ground (floor coverings as per plans: Tiles to wet areas + Entry + Fam/Meal/Kit + Bed Hall, Carpet with underlay to rest of house)'
                ],
                sec3 : [
                    'Ext cav bwk with NO CAVITY WALL INSULATION',
                    'Ext cav bwk INSULATION AS PER PREVIOUS RECOMMENDATION'
                ],
                sec4 : [
                    'Int bwk',
                    'Framed wall',
                ],
                sec5 : [
                    'R4.0 ceiling insulation, (insulated vertical walls abutting roof space) - ceiling penetrations included in software where applicable.'
                ],
                sec6 : [
                    'Metal roof (uninsulated - default SAF >.30 - Light)',
                    'Tiled roof (uninsulated - default SAF >.30 - Light)'
                ],
                sec7 : [
                    'Single clear windows (AFRC Defaults for U-value and SHGC)',
                    'AFRC Defaults for low E \'clear\' as per plans',
                    'Single clear windows (AFRC Dowell for U-value and SHGC)',
                    'AFRC Dowell "Smartglass" for low E \'clear\' as per plans'
                ],
                sec8 : [
                    'NO int gas pt',
                    'Int gas pt'
                ],
                sec9 : [
                    'Draft seals to exhaust fans',
                    'No draft seals to exhaust fans'
                ],
                sec10 : [
                    'NO recessed downlights nominated',
                    'LED recessed downlights nominated',
                    'IC rated LED recessed downlights nominated'
                ],
                sec11 : [
                    'Site obstruction taken into account as per site survey',
                    'Default site obstruction taken into account',
                ]
            }
        }
    },
    methods : {
        redirect(page, params) {
            this.$router.push({name : page, params: params});
        },
        getJobType(id) {
            return this.job_types[id - 1] == undefined ? '' : this.job_types[id - 1].type;
        },
        getMenus(account_type) {
            var menus = [];
            this.access_menus.forEach(m => {
               if(m.access.indexOf(account_type) >= 0) {
                    menus.push(m);
               } 
            });

            return menus;
        },
        openLink(section, id, type, file) {
            if(type == 'link') {
                window.open(file, '_blank');
            }
            else {
                window.open(this.$store.state.host_url + '/uploads/' + section + '/' + id + '/' + file, '_blank');
            }
        },
        sendClientNotification(id) {
            return axios({
                url : process.env.VUE_APP_API_URL + `/jobs/${id}/email-notification/send`,
                method : 'POST',
                headers : {
                    'Accept' : 'application/json',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            });
        }
    }
}